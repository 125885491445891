<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-form
      @submit.prevent="execute"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--header">
          <v-icon color="primary" size="40">
            fa-regular fa-pen-to-square
          </v-icon>
        </div>
        <div class="b-dialog-question-card--body">
          <v-textarea
            id="message"
            name="message"
            label="Mensagem"
            rows="8"
            outlined
            dense
            required
            :rules="[required]"
            v-model="item.message"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Voltar"
            @click="closeModal"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Editar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { supportMixin } from '@/mixins/support';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin, supportMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    valid: true,
    item: {}
  }),

  created() {
    this.item = this.model;
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const supportService = new SupportService();
        const { status } = await supportService.putTicketMessage(this.item);

        if (status === 204) {
          const model = {
            ticketId: this.item.ticketId,
            isDemandedTab: this.item.isDemandedTab
          };

          this.getMessages(model);
          showMessage('success', 'Mensagem atualizada com sucesso');
          this.closeModal();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
